import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  ApiModel: () => import('../../components/ApiModel.js' /* webpackChunkName: "components/api-model" */).then(c => wrapFunctional(c.default || c)),
  CameraInfo: () => import('../../components/CameraInfo.vue' /* webpackChunkName: "components/camera-info" */).then(c => wrapFunctional(c.default || c)),
  ClearwaterAd: () => import('../../components/ClearwaterAd.vue' /* webpackChunkName: "components/clearwater-ad" */).then(c => wrapFunctional(c.default || c)),
  ErrorPage: () => import('../../components/ErrorPage.vue' /* webpackChunkName: "components/error-page" */).then(c => wrapFunctional(c.default || c)),
  LeadRegistration: () => import('../../components/LeadRegistration.vue' /* webpackChunkName: "components/lead-registration" */).then(c => wrapFunctional(c.default || c)),
  LocationMap: () => import('../../components/LocationMap.vue' /* webpackChunkName: "components/location-map" */).then(c => wrapFunctional(c.default || c)),
  LogIn: () => import('../../components/LogIn.vue' /* webpackChunkName: "components/log-in" */).then(c => wrapFunctional(c.default || c)),
  LogInPassport: () => import('../../components/LogInPassport.vue' /* webpackChunkName: "components/log-in-passport" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  MapSvg: () => import('../../components/MapSvg.vue' /* webpackChunkName: "components/map-svg" */).then(c => wrapFunctional(c.default || c)),
  MortgageCalculator: () => import('../../components/MortgageCalculator.vue' /* webpackChunkName: "components/mortgage-calculator" */).then(c => wrapFunctional(c.default || c)),
  OfficeCard: () => import('../../components/OfficeCard.vue' /* webpackChunkName: "components/office-card" */).then(c => wrapFunctional(c.default || c)),
  OfficeMap: () => import('../../components/OfficeMap.vue' /* webpackChunkName: "components/office-map" */).then(c => wrapFunctional(c.default || c)),
  Overlay: () => import('../../components/Overlay.vue' /* webpackChunkName: "components/overlay" */).then(c => wrapFunctional(c.default || c)),
  PageBottom: () => import('../../components/PageBottom.vue' /* webpackChunkName: "components/page-bottom" */).then(c => wrapFunctional(c.default || c)),
  PropertyCard: () => import('../../components/PropertyCard.vue' /* webpackChunkName: "components/property-card" */).then(c => wrapFunctional(c.default || c)),
  PropertyFlyer: () => import('../../components/PropertyFlyer.vue' /* webpackChunkName: "components/property-flyer" */).then(c => wrapFunctional(c.default || c)),
  PropertySave: () => import('../../components/PropertySave.vue' /* webpackChunkName: "components/property-save" */).then(c => wrapFunctional(c.default || c)),
  ScheduleTour: () => import('../../components/ScheduleTour.vue' /* webpackChunkName: "components/schedule-tour" */).then(c => wrapFunctional(c.default || c)),
  SearchMobile: () => import('../../components/SearchMobile.vue' /* webpackChunkName: "components/search-mobile" */).then(c => wrapFunctional(c.default || c)),
  SearchTop: () => import('../../components/SearchTop.vue' /* webpackChunkName: "components/search-top" */).then(c => wrapFunctional(c.default || c)),
  ShareProperty: () => import('../../components/ShareProperty.vue' /* webpackChunkName: "components/share-property" */).then(c => wrapFunctional(c.default || c)),
  VideoBackground: () => import('../../components/VideoBackground.vue' /* webpackChunkName: "components/video-background" */).then(c => wrapFunctional(c.default || c)),
  VuetifyLogo: () => import('../../components/VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => wrapFunctional(c.default || c)),
  AgentFilters: () => import('../../components/agentFilters.vue' /* webpackChunkName: "components/agent-filters" */).then(c => wrapFunctional(c.default || c)),
  PropertiesMap: () => import('../../components/propertiesMap.vue' /* webpackChunkName: "components/properties-map" */).then(c => wrapFunctional(c.default || c)),
  SmTextArea: () => import('../../components/smTextArea.vue' /* webpackChunkName: "components/sm-text-area" */).then(c => wrapFunctional(c.default || c)),
  LayoutsHome: () => import('../../components/layouts/Home.vue' /* webpackChunkName: "components/layouts-home" */).then(c => wrapFunctional(c.default || c)),
  LayoutsStandard: () => import('../../components/layouts/Standard.vue' /* webpackChunkName: "components/layouts-standard" */).then(c => wrapFunctional(c.default || c)),
  LayoutsStandardHalf: () => import('../../components/layouts/StandardHalf.vue' /* webpackChunkName: "components/layouts-standard-half" */).then(c => wrapFunctional(c.default || c)),
  LayoutsStandardLeft: () => import('../../components/layouts/StandardLeft.vue' /* webpackChunkName: "components/layouts-standard-left" */).then(c => wrapFunctional(c.default || c)),
  LayoutsStandardRight: () => import('../../components/layouts/StandardRight.vue' /* webpackChunkName: "components/layouts-standard-right" */).then(c => wrapFunctional(c.default || c)),
  NavigationLeftMenu: () => import('../../components/navigation/LeftMenu.vue' /* webpackChunkName: "components/navigation-left-menu" */).then(c => wrapFunctional(c.default || c)),
  NavigationRightMenu: () => import('../../components/navigation/RightMenu.vue' /* webpackChunkName: "components/navigation-right-menu" */).then(c => wrapFunctional(c.default || c)),
  NavigationToolbarUser: () => import('../../components/navigation/ToolbarUser.vue' /* webpackChunkName: "components/navigation-toolbar-user" */).then(c => wrapFunctional(c.default || c)),
  NavigationTopMenu: () => import('../../components/navigation/TopMenu.vue' /* webpackChunkName: "components/navigation-top-menu" */).then(c => wrapFunctional(c.default || c)),
  BlocksAgentFilter: () => import('../../components/blocks/AgentFilter.vue' /* webpackChunkName: "components/blocks-agent-filter" */).then(c => wrapFunctional(c.default || c)),
  BlocksAppStoreHorizontal: () => import('../../components/blocks/AppStoreHorizontal.vue' /* webpackChunkName: "components/blocks-app-store-horizontal" */).then(c => wrapFunctional(c.default || c)),
  BlocksAppStoreStacked: () => import('../../components/blocks/AppStoreStacked.vue' /* webpackChunkName: "components/blocks-app-store-stacked" */).then(c => wrapFunctional(c.default || c)),
  BlocksBlockedButton: () => import('../../components/blocks/BlockedButton.vue' /* webpackChunkName: "components/blocks-blocked-button" */).then(c => wrapFunctional(c.default || c)),
  BlocksBodyHeadline: () => import('../../components/blocks/BodyHeadline.vue' /* webpackChunkName: "components/blocks-body-headline" */).then(c => wrapFunctional(c.default || c)),
  BlocksBreadcrumbs: () => import('../../components/blocks/Breadcrumbs.vue' /* webpackChunkName: "components/blocks-breadcrumbs" */).then(c => wrapFunctional(c.default || c)),
  BlocksBulletBox: () => import('../../components/blocks/BulletBox.vue' /* webpackChunkName: "components/blocks-bullet-box" */).then(c => wrapFunctional(c.default || c)),
  BlocksCallToAction: () => import('../../components/blocks/CallToAction.vue' /* webpackChunkName: "components/blocks-call-to-action" */).then(c => wrapFunctional(c.default || c)),
  BlocksCallToActionSlider: () => import('../../components/blocks/CallToActionSlider.vue' /* webpackChunkName: "components/blocks-call-to-action-slider" */).then(c => wrapFunctional(c.default || c)),
  BlocksELeadText: () => import('../../components/blocks/ELeadText.vue' /* webpackChunkName: "components/blocks-e-lead-text" */).then(c => wrapFunctional(c.default || c)),
  BlocksEText: () => import('../../components/blocks/EText.vue' /* webpackChunkName: "components/blocks-e-text" */).then(c => wrapFunctional(c.default || c)),
  BlocksETextWHeading: () => import('../../components/blocks/ETextWHeading.vue' /* webpackChunkName: "components/blocks-e-text-w-heading" */).then(c => wrapFunctional(c.default || c)),
  BlocksEmailLink: () => import('../../components/blocks/EmailLink.vue' /* webpackChunkName: "components/blocks-email-link" */).then(c => wrapFunctional(c.default || c)),
  BlocksExpectationsDetails: () => import('../../components/blocks/ExpectationsDetails.vue' /* webpackChunkName: "components/blocks-expectations-details" */).then(c => wrapFunctional(c.default || c)),
  BlocksFavorites: () => import('../../components/blocks/Favorites.vue' /* webpackChunkName: "components/blocks-favorites" */).then(c => wrapFunctional(c.default || c)),
  BlocksFeaturedProperties: () => import('../../components/blocks/FeaturedProperties.vue' /* webpackChunkName: "components/blocks-featured-properties" */).then(c => wrapFunctional(c.default || c)),
  BlocksHomeLocationMap: () => import('../../components/blocks/HomeLocationMap.vue' /* webpackChunkName: "components/blocks-home-location-map" */).then(c => wrapFunctional(c.default || c)),
  BlocksHoverGrid: () => import('../../components/blocks/HoverGrid.vue' /* webpackChunkName: "components/blocks-hover-grid" */).then(c => wrapFunctional(c.default || c)),
  BlocksImage: () => import('../../components/blocks/Image.vue' /* webpackChunkName: "components/blocks-image" */).then(c => wrapFunctional(c.default || c)),
  BlocksImageCallToAction: () => import('../../components/blocks/ImageCallToAction.vue' /* webpackChunkName: "components/blocks-image-call-to-action" */).then(c => wrapFunctional(c.default || c)),
  BlocksImageGallery: () => import('../../components/blocks/ImageGallery.vue' /* webpackChunkName: "components/blocks-image-gallery" */).then(c => wrapFunctional(c.default || c)),
  BlocksImageOverlay: () => import('../../components/blocks/ImageOverlay.vue' /* webpackChunkName: "components/blocks-image-overlay" */).then(c => wrapFunctional(c.default || c)),
  BlocksLeadForm: () => import('../../components/blocks/LeadForm.vue' /* webpackChunkName: "components/blocks-lead-form" */).then(c => wrapFunctional(c.default || c)),
  BlocksLeadText: () => import('../../components/blocks/LeadText.vue' /* webpackChunkName: "components/blocks-lead-text" */).then(c => wrapFunctional(c.default || c)),
  BlocksLeadershipText: () => import('../../components/blocks/LeadershipText.vue' /* webpackChunkName: "components/blocks-leadership-text" */).then(c => wrapFunctional(c.default || c)),
  BlocksLegacyPageContent: () => import('../../components/blocks/LegacyPageContent.vue' /* webpackChunkName: "components/blocks-legacy-page-content" */).then(c => wrapFunctional(c.default || c)),
  BlocksLegacyPageHeader: () => import('../../components/blocks/LegacyPageHeader.vue' /* webpackChunkName: "components/blocks-legacy-page-header" */).then(c => wrapFunctional(c.default || c)),
  BlocksLegacyPageList: () => import('../../components/blocks/LegacyPageList.vue' /* webpackChunkName: "components/blocks-legacy-page-list" */).then(c => wrapFunctional(c.default || c)),
  BlocksLegacyPageSide: () => import('../../components/blocks/LegacyPageSide.vue' /* webpackChunkName: "components/blocks-legacy-page-side" */).then(c => wrapFunctional(c.default || c)),
  BlocksLegacyProperties: () => import('../../components/blocks/LegacyProperties.vue' /* webpackChunkName: "components/blocks-legacy-properties" */).then(c => wrapFunctional(c.default || c)),
  BlocksLinkedImage: () => import('../../components/blocks/LinkedImage.vue' /* webpackChunkName: "components/blocks-linked-image" */).then(c => wrapFunctional(c.default || c)),
  BlocksList: () => import('../../components/blocks/List.vue' /* webpackChunkName: "components/blocks-list" */).then(c => wrapFunctional(c.default || c)),
  BlocksLocationMap: () => import('../../components/blocks/LocationMap.vue' /* webpackChunkName: "components/blocks-location-map" */).then(c => wrapFunctional(c.default || c)),
  BlocksLocationSearch: () => import('../../components/blocks/LocationSearch.vue' /* webpackChunkName: "components/blocks-location-search" */).then(c => wrapFunctional(c.default || c)),
  BlocksMarketFrame: () => import('../../components/blocks/MarketFrame.vue' /* webpackChunkName: "components/blocks-market-frame" */).then(c => wrapFunctional(c.default || c)),
  BlocksMarketGrid: () => import('../../components/blocks/MarketGrid.vue' /* webpackChunkName: "components/blocks-market-grid" */).then(c => wrapFunctional(c.default || c)),
  BlocksNewsletterPopForm: () => import('../../components/blocks/NewsletterPopForm.vue' /* webpackChunkName: "components/blocks-newsletter-pop-form" */).then(c => wrapFunctional(c.default || c)),
  BlocksOfficeAgents: () => import('../../components/blocks/OfficeAgents.vue' /* webpackChunkName: "components/blocks-office-agents" */).then(c => wrapFunctional(c.default || c)),
  BlocksOfficeBio: () => import('../../components/blocks/OfficeBio.vue' /* webpackChunkName: "components/blocks-office-bio" */).then(c => wrapFunctional(c.default || c)),
  BlocksOffices: () => import('../../components/blocks/Offices.vue' /* webpackChunkName: "components/blocks-offices" */).then(c => wrapFunctional(c.default || c)),
  BlocksPageBottom: () => import('../../components/blocks/PageBottom.vue' /* webpackChunkName: "components/blocks-page-bottom" */).then(c => wrapFunctional(c.default || c)),
  BlocksPageHeader: () => import('../../components/blocks/PageHeader.vue' /* webpackChunkName: "components/blocks-page-header" */).then(c => wrapFunctional(c.default || c)),
  BlocksPhoneLink: () => import('../../components/blocks/PhoneLink.vue' /* webpackChunkName: "components/blocks-phone-link" */).then(c => wrapFunctional(c.default || c)),
  BlocksProfileBio: () => import('../../components/blocks/ProfileBio.vue' /* webpackChunkName: "components/blocks-profile-bio" */).then(c => wrapFunctional(c.default || c)),
  BlocksProfileCard: () => import('../../components/blocks/ProfileCard.vue' /* webpackChunkName: "components/blocks-profile-card" */).then(c => wrapFunctional(c.default || c)),
  BlocksProfiles: () => import('../../components/blocks/Profiles.vue' /* webpackChunkName: "components/blocks-profiles" */).then(c => wrapFunctional(c.default || c)),
  BlocksProperties: () => import('../../components/blocks/Properties.vue' /* webpackChunkName: "components/blocks-properties" */).then(c => wrapFunctional(c.default || c)),
  BlocksProperty: () => import('../../components/blocks/Property.vue' /* webpackChunkName: "components/blocks-property" */).then(c => wrapFunctional(c.default || c)),
  BlocksPropertyActions: () => import('../../components/blocks/PropertyActions.vue' /* webpackChunkName: "components/blocks-property-actions" */).then(c => wrapFunctional(c.default || c)),
  BlocksPropertyMap: () => import('../../components/blocks/PropertyMap.vue' /* webpackChunkName: "components/blocks-property-map" */).then(c => wrapFunctional(c.default || c)),
  BlocksRawHTML: () => import('../../components/blocks/RawHTML.vue' /* webpackChunkName: "components/blocks-raw-h-t-m-l" */).then(c => wrapFunctional(c.default || c)),
  BlocksSearchPageHeader: () => import('../../components/blocks/SearchPageHeader.vue' /* webpackChunkName: "components/blocks-search-page-header" */).then(c => wrapFunctional(c.default || c)),
  BlocksSectionHeadline: () => import('../../components/blocks/SectionHeadline.vue' /* webpackChunkName: "components/blocks-section-headline" */).then(c => wrapFunctional(c.default || c)),
  BlocksSpacer: () => import('../../components/blocks/Spacer.vue' /* webpackChunkName: "components/blocks-spacer" */).then(c => wrapFunctional(c.default || c)),
  BlocksStatement: () => import('../../components/blocks/Statement.vue' /* webpackChunkName: "components/blocks-statement" */).then(c => wrapFunctional(c.default || c)),
  BlocksTagsPageHeader: () => import('../../components/blocks/TagsPageHeader.vue' /* webpackChunkName: "components/blocks-tags-page-header" */).then(c => wrapFunctional(c.default || c)),
  BlocksTagsPageList: () => import('../../components/blocks/TagsPageList.vue' /* webpackChunkName: "components/blocks-tags-page-list" */).then(c => wrapFunctional(c.default || c)),
  BlocksTestimonials: () => import('../../components/blocks/Testimonials.vue' /* webpackChunkName: "components/blocks-testimonials" */).then(c => wrapFunctional(c.default || c)),
  BlocksTextHeader: () => import('../../components/blocks/TextHeader.vue' /* webpackChunkName: "components/blocks-text-header" */).then(c => wrapFunctional(c.default || c)),
  BlocksTimeline: () => import('../../components/blocks/Timeline.vue' /* webpackChunkName: "components/blocks-timeline" */).then(c => wrapFunctional(c.default || c)),
  BlocksVideoHeader: () => import('../../components/blocks/VideoHeader.vue' /* webpackChunkName: "components/blocks-video-header" */).then(c => wrapFunctional(c.default || c)),
  BlocksWebcam: () => import('../../components/blocks/Webcam.vue' /* webpackChunkName: "components/blocks-webcam" */).then(c => wrapFunctional(c.default || c)),
  BlocksWebcams: () => import('../../components/blocks/Webcams.vue' /* webpackChunkName: "components/blocks-webcams" */).then(c => wrapFunctional(c.default || c)),
  BlocksHeadline: () => import('../../components/blocks/headline.vue' /* webpackChunkName: "components/blocks-headline" */).then(c => wrapFunctional(c.default || c)),
  SearchAcres: () => import('../../components/search/acres.vue' /* webpackChunkName: "components/search-acres" */).then(c => wrapFunctional(c.default || c)),
  SearchBedsBaths: () => import('../../components/search/bedsBaths.vue' /* webpackChunkName: "components/search-beds-baths" */).then(c => wrapFunctional(c.default || c)),
  SearchLocation: () => import('../../components/search/location.vue' /* webpackChunkName: "components/search-location" */).then(c => wrapFunctional(c.default || c)),
  SearchMap: () => import('../../components/search/map.vue' /* webpackChunkName: "components/search-map" */).then(c => wrapFunctional(c.default || c)),
  SearchMls: () => import('../../components/search/mls.vue' /* webpackChunkName: "components/search-mls" */).then(c => wrapFunctional(c.default || c)),
  SearchPrice: () => import('../../components/search/price.vue' /* webpackChunkName: "components/search-price" */).then(c => wrapFunctional(c.default || c)),
  SearchPropertyTypes: () => import('../../components/search/propertyTypes.vue' /* webpackChunkName: "components/search-property-types" */).then(c => wrapFunctional(c.default || c)),
  SearchSort: () => import('../../components/search/sort.vue' /* webpackChunkName: "components/search-sort" */).then(c => wrapFunctional(c.default || c)),
  SectionsEBody: () => import('../../components/sections/EBody.vue' /* webpackChunkName: "components/sections-e-body" */).then(c => wrapFunctional(c.default || c)),
  SectionsIntro: () => import('../../components/sections/Intro.vue' /* webpackChunkName: "components/sections-intro" */).then(c => wrapFunctional(c.default || c)),
  SectionsSide: () => import('../../components/sections/Side.vue' /* webpackChunkName: "components/sections-side" */).then(c => wrapFunctional(c.default || c)),
  SectionsTop: () => import('../../components/sections/Top.vue' /* webpackChunkName: "components/sections-top" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
