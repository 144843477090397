import createRepository from "~/api/Repository.js"

export default (ctx, inject) => {
  const repositoryWithAxios = createRepository(ctx.$axios)

  const repositories = {
    // example
    // mailchimps: repositoryWithAxios("mailchimps"),
    ads: repositoryWithAxios("clearwaterads"),
    // properties: repositoryWithAxios("front/properties"),
    propertytypes: repositoryWithAxios("front/property-types"),
    leads: repositoryWithAxios("front/leads"),
    frontproperties: repositoryWithAxios("front/properties"),
    webcams: repositoryWithAxios("front/webcams"),
    offices: repositoryWithAxios("front/offices"),
    pages: repositoryWithAxios("front/pages"),
    users: repositoryWithAxios("front/users"),
    // usersProperties: repositoryWithAxios("users", "properties"),
    fronttestimonials: repositoryWithAxios("fronttestimonials"),
    frontagents: repositoryWithAxios("frontagents"),
    legacypages: repositoryWithAxios("front/legacy-pages")
  }

  inject("repositories", repositories)
}
